/*
 * @Author: soluPeng
 * @Date: 2021-11-10 16:46:13
 * @LastEditTime: 2021-12-17 15:09:34
 * @LastEditors: lanxx
 * @Description: Do not edit
 */
import axios from 'axios'

const httpService = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json'
  }
})

httpService.interceptors.request.use(
  (config) => {
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

httpService.interceptors.response.use(
  (response) => {
    const res = response.data
    if (res.code !== 0) {
      return Promise.reject(res)
    } else {
      return res
    }
  },
  (error) => {
    if (error && error.response) {
      switch (error.response.status) {
        case 400:
          error.message = '错误请求'
          break
        case 401:
          error.message = '未授权，请重新登录'
          break
        case 403:
          error.message = '拒绝访问'
          break
        case 404:
          error.message = '请求错误,未找到该资源'
          break
        case 405:
          error.message = '请求方法未允许'
          break
        case 408:
          error.message = '请求超时'
          break
        case 500:
          error.message = '服务器端出错'
          break
        case 501:
          error.message = '网络未实现'
          break
        case 502:
          error.message = '网络错误'
          break
        case 503:
          error.message = '服务不可用'
          break
        case 504:
          error.message = '网络超时'
          break
        case 505:
          error.message = 'http版本不支持该请求'
          break
        default:
          error.message = `未知错误${error.response.status}`
      }
    } else {
      error.message = '连接到服务器失败'
    }
    console.log(error.message)
    return Promise.reject(error)
  }
)

export default httpService

export const $getJson = (areaCode) => {
  return new Promise(resolve => {
    axios({
      method: 'get',
      url: 'https://zhenongfu.oss-cn-hangzhou.aliyuncs.com/prosperity-garden/assets/mapdata/' + areaCode + '.json',
    }).then(res => {
      resolve(res)
    })
  })
}