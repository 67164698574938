<!--
 * @Author: lanxx
 * @Date: 2021-11-11 10:19:36
 * @LastEditTime: 2022-05-18 16:45:38
 * @LastEditors: lanxx
 * @Description: Do not edit
 * @FilePath: \prosperity-garden-ui\src\components\top-header.vue
-->
<template>
  <div class="header">
    <h1>浙农服·海宁市 — {{title}}</h1>
    <!-- <div class="back" @click="$router.back(-1)" v-if="link"></div> -->
    <ul class="btns">
      <li :class="{'link': link === 'county'}"><div @click="goPage('county', '菜园布局')">菜园布局</div></li>
      <li :class="{'link': link === 'master'}"><div @click="goPage('master')">菜园主人</div></li>
      <li :class="{'link': link === 'construction'}"><div @click="goPage('construction')">菜园建设</div></li>
      <li :class="{'link': link === 'serve'}"><div @click="goPage('serve')">菜园服务</div></li>
      <li :class="{'link': link === 'marketing'}"><div @click="goPage('marketing')">菜园营销</div></li>
      <li :class="{'link': link === 'wealthy'}"><div @click="goPage('wealthy')">菜园共富</div></li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ["name"],
  name: "top-header",
  data() {
    return {
      title: '共富菜园'
    }
  },
  computed: {
    link() {
      return this.$route.name
    },
    
  },
  methods: {
    goPage(name) {
      // this.title = title
      if (this.link === name) {
        return false
      }
      this.$router.push({
        name,
      })
    },
  }
};
</script>

<style lang="scss" scoped>
.header {
  overflow: hidden;
  position: relative;
  height: 74px;
  background: url(https://zhenongfu.oss-cn-hangzhou.aliyuncs.com/prosperity-garden/assets/images/top-bg.png)
    no-repeat left;
  background-size: auto 100%;
  z-index: 999;
  h1 {
    margin: 9px 0px 0px 30px;
    width: 547px;
    height: 56px;
    line-height: 56px;
    font-size: 38px;
    letter-spacing: 4px;
    text-shadow: 0 0 8px rgba(0, 149, 255, 0.89);
  }
  .btns {
    position: absolute;
    left: 630px;
    top: 22px;
    li {
      margin-left: -30px;
      float: left;
      width: 193px;
      height: 36px;
      text-align: center;
      line-height: 36px;
      font-size: 20px;
      color: #fff;
      font-weight: bold;
      letter-spacing: 2px;
      text-shadow: 0 0 10px rgba(0, 149, 255, 0.89);
      background: url(../assets/images/btn.png) no-repeat;
      background-size: 100% 100%;
      div {
        height: 100%;
        cursor: pointer;
        margin: 0 35px;
      }
    }
    .link {
      background: url('../assets/images/nav.png') no-repeat;
      background-size: 100% 100%;
    }
  }
  .back {
    position: absolute;
    cursor: pointer;
    top: 30px;
    right: 46px;
    width: 160px;
    height: 35px;
    background: url(https://zhenongfu.oss-cn-hangzhou.aliyuncs.com/prosperity-garden/assets/images/back.png);
    background-size: 100% 100%;
  }
}
</style>