/*
 * @Author: lanxx
 * @Date: 2021-11-11 09:31:38
 * @LastEditTime: 2021-12-17 15:11:52
 * @LastEditors: lanxx
 * @Description: Do not edit
 * @FilePath: \prosperity-garden-ui\src\utils\index.js
 */
/**
 * context 转 map
 * @param  {[type]} ctx [description]
 * @return {[type]}     [description]
 */
 import _assign from 'lodash/assign'

export function ctxToMap(ctx) {
  const apiMap = {}
  for (const key of ctx.keys()) {
    const fileKey = key.replace(/\.\/|\.js/g, '')
    const context = ctx(key).default
    if (context) {
      apiMap[fileKey] = context
    }
  }
  return apiMap
}

export function convertActions(apifunc, prefix = '') {
  let actions = {}
  for (const key in apifunc) {
    if (typeof apifunc[key] === 'function') {
      actions[prefix + key] = (context, data) => {
        return apifunc[key].call(this, data)
      }
    }
    if (typeof apifunc[key] === 'object') {
      actions = _assign(actions, convertActions(apifunc[key], key + '.'))
    }
  }
  return actions
}

// 将一个数组按照n个一份，分成若干数组
export function splitToChunks(array, subGroupLength) {
  let index = 0;
  let newArray = [];
  while (index < array.length) {
    newArray.push(array.slice(index, index += subGroupLength));
  }
  return newArray;
}