<!--
 * @Author: lanxx
 * @Date: 2021-11-11 10:19:36
 * @LastEditTime: 2022-01-10 20:00:37
 * @LastEditors: lanxx
 * @Description: Do not edit
 * @FilePath: \prosperity-garden-ui\src\components\title2.vue
-->
<template>
  <div class="title2">{{ name }}<slot /></div>
</template>

<script>
export default {
  props: ["name"],
  name: "title2",
};
</script>

<style lang="scss" scoped>
.title2 {
  position: relative;
  height: 40px;
  background: url(https://zhenongfu.oss-cn-hangzhou.aliyuncs.com/prosperity-garden/assets/images/title2-bg.png);
  background-size: 100% 100%;
  padding-left: 47px;
  line-height: 40px;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 2px;
  text-shadow: 0 0 8px rgba(0, 149, 255, 0.89);
  max-width: 500px;
  color: #fff;
}
</style>