/*
 * @Author: lanxx
 * @Date: 2021-10-14 10:13:11
 * @LastEditTime: 2022-01-13 14:57:56
 * @LastEditors: lanxx
 * @Description: Do not edit
 * @FilePath: \prosperity-garden-ui\src\mixins\index.js
 */
import vueSeamlessScroll from "vue-seamless-scroll";
import title2 from "@/components/title2";
import topHeader from "@/components/top-header";

var myMixin = {
  components: {
    vueSeamlessScroll,
    title2,
    topHeader,
  },
  computed: {
    defaultOption() {
      return {
        step: 0.4, // 数值越大速度滚动越快
        limitMoveNum: 9, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: false, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },
  methods: {
    numFilter(value, wan = 1) {
      if (typeof (value) === 'undefined') return ''
      if (value === '') return ''
      if (value === 0) return 0
      const newValue = (value / wan)
      const flag = String(newValue).indexOf('.') + 1
      if (flag > 0) {
        return newValue.toFixed(2)
      } else {
        return newValue
      }
    }
  },
  filters: {
    numFilter(value, wan = 1) {
      if (typeof (value) === 'undefined') return ''
      if (value === '') return ''
      if (value === 0) return 0
      const newValue = (value / wan)
      const flag = String(newValue).indexOf('.') + 1
      if (flag > 0) {
        return newValue.toFixed(2)
      } else {
        return newValue
      }
    }
  }
}
export default myMixin