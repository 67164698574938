/*
 * @Descripttion: 
 * @version: 
 * @Author: soluPeng
 * @Date: 2021-11-10 15:32:28
 * @LastEditors: lanxx
 * @LastEditTime: 2021-12-17 15:07:21
 */
import Vue from 'vue'
import Element from 'element-ui'
import App from './App.vue'
import router from './router'
// import store from './store'
import 'element-ui/lib/theme-chalk/index.css'
import "./assets/styles/style.scss"
import httpApi from '@/api'
import mixins from './mixins'
import * as echarts from "echarts";

Vue.prototype.$echarts = echarts
Vue.use(httpApi)
Vue.mixin(mixins)
Vue.use(Element, {
  size: 'small',
  zIndex: 3000
})

Vue.config.productionTip = false

new Vue({
  router,
  // store,
  render: h => h(App)
}).$mount('#app')