/*
 * @Author: lanxx
 * @Date: 2021-10-15 19:41:36
 * @LastEditTime: 2022-05-27 22:04:18
 * @LastEditors: lanxx
 * @Description: Do not edit
 */
import fetch from '@/utils/fetch'

export default {
  getCountyTownDetail: (params) => fetch.get('/api/statistics/operation/' + params.areaCode),
  getGardenDetail: (params) => fetch.get('/api/statistics/operation/gardenDetail/' + params.id),
  countByLightLevel: () => fetch.get('/api/statistics/operation/countByLightLevel'),
  incomeIncrement: () => fetch.get('/api/statistics/operation/incomeIncrement'),
  groupByIncomeType: (params) => fetch.get('/api/statistics/operation/groupByIncomeType/' + params.householdId),
  getPlayAddr: (params) => fetch.get('/api/noAuth/operation/playAddr/' + params.videoCode),
  coRich: (params) => fetch.get('/api/statistics/operation/coRich/' + params),
  owner: (params) => fetch.get('/api/statistics/operation/owner/' + params),
  service: (params) => fetch.get('/api/statistics/operation/service/' + params),
  marketing: (params) => fetch.get('/api/statistics/operation/marketing/' + params),
  statisticsByConfig: () => fetch.get('/api/statisticsCaiYuan/statisticsByConfig/'),
  specialist: () => fetch.get('/api/statisticsCaiYuan/specialist/'),//专家概况
  weatherInfo: () => fetch.get('/api/statisticsCaiYuan/weatherInfo/'),//气象服务
  gardenInfo: () => fetch.get('/api/statisticsCaiYuan/gardenInfo/'),//菜园农资情况
  gardenInfoByTown: () => fetch.get('/api/statisticsCaiYuan/gardenInfoSum'),
  caiyuanTotalInfo: () => fetch.get('/api/statisticsCaiYuan/caiyuanTotalInfo')
}