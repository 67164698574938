/*
 * @Descripttion: 
 * @version: 
 * @Author: soluPeng
 * @Date: 2021-11-10 15:32:28
 * @LastEditors: lanxx
 * @LastEditTime: 2021-12-17 15:19:05
 */
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
  path: '/',
  name: 'county',
  component: () => import('@/views/county.vue')
}, {
  path: '/marketing',
  name: 'marketing',
  component: () => import('@/views/marketing.vue')
}, {
  path: '/master',
  name: 'master',
  component: () => import('@/views/master.vue')
}, {
  path: '/construction',
  name: 'construction',
  component: () => import('@/views/construction.vue')
}, {
  path: '/serve',
  name: 'serve',
  component: () => import('@/views/serve.vue')
}, {
  path: '/wealthy',
  name: 'wealthy',
  component: () => import('@/views/wealthy.vue')
}, {
  path: '/town/:area',
  name: 'town',
  component: () => import('@/views/town.vue')
}, {
  path: '/garden/:id',
  name: 'garden',
  component: () => import('@/views/garden.vue')
}, {
  path: '*',
  redirect: '/'
}]

const router = new VueRouter({
  // mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {
        x: 0,
        y: 0
      }
    }
  }
})

export default router