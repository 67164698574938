/*
 * @Descripttion: 
 * @version: 
 * @Author: soluPeng
 * @Date: 2021-11-10 16:54:23
 * @LastEditors: soluPeng
 * @LastEditTime: 2021-11-12 16:27:09
 */
import {
    ctxToMap
} from '@/utils'
const ctx = require.context('./modules', true, /\.js$/)

const install = (Vue) => {
    Vue.prototype.$api = {
        ...ctxToMap(ctx)
    }
}

export default {
    install
}